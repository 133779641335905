<template>


  <div ref="conditions" class="report_items report_seeds" :class="{'active': showMore}">

    <div v-if="showButton" class="btn_less" @click="toggle">
      <i class="icon-caret-down down"/>
      <i class="icon-caret-up up"/>
    </div>


    <!-- {{useDiary().getDiaryWateringVolume(props.diary)}} -->


    <DiaryViewConditionsItem
      v-for="(item, itemKey) in Equips" 
      :key="itemKey"
      :id="item.id"
      :brand-id="item.brand_id"
      :icon="item.icon"
      :logo="item.logo"
      :name="item.name"
      :name_link="item.name_link"
      :hint="item.hint"
      :hint_link="item.hint_link"
      :category="item.category"
      :count="item.count"
      :data-props="item.dataProps"
      :faza="item.faza"
    /> 

   
  </div>
</template>

<script setup lang="ts">

import type { DiaryConditionItemPageProps }  from "@/types/other"

import type { DiaryEquip, WeekNutrient, Week, Diary, DiaryNutrient, DiaryMethod, DiaryMedium }  from "@/types/diary"



const { t } = useI18n();
const { $constants, $convert } = useNuxtApp();
import debounce from 'lodash/debounce';

const mapperSetupDiaryIcon = {
  1: "/images/setup_diary/setup_diary_icons-07.svg",
  2: "/images/setup_diary/setup_diary_icons-08.svg",
  3: "/images/setup_diary/setup_diary_icons-06.svg",
}

interface PageProps {
  diary: Diary
}
const props = defineProps<PageProps>()

const showMore = ref(false);
const conditions = ref(null);
const showButton = ref(false);
 


const Equips = computed( () => useDiary().headDiarySetup(props.diary));
 




const checkOverflow = debounce((event) => {

  if (conditions.value) {
    let totalWidth = 0;
    const children = conditions.value.querySelectorAll('.item');

    children.forEach((child) => {
      totalWidth += child.offsetWidth ;
    });

    showButton.value = totalWidth > conditions.value.offsetWidth*3 - 30;
  }

}, 300);


const toggle = function() {
  showMore.value = !showMore.value;
}


const openWiki = (type, name) => {
  useWikiModal().open(type, name);
}

onMounted(() => {
  checkOverflow();
  window.addEventListener('resize', checkOverflow);
});

onUpdated(checkOverflow);

onBeforeUnmount(() => {
  window.removeEventListener('resize', checkOverflow);
})
</script>





<style scoped>



.report_items{
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  max-height: 155px;
  align-items: flex-start;
  position: relative;
  padding-right: 45px;
  margin-top: 10px;
  margin-bottom: 15px;
  gap: 10px;
}
.report_items.active{
  max-height: none;
}
.report_items .item.condition_item{
  cursor: default;
}
.report_items .item{
  display: flex;
  position: relative;
  align-items: center;
  height: 45px;
}
.report_items .item .logo{
  max-height: 40px;
  max-width: 40px;
  margin-right: 10px;
  width: 100%;
}
.report_items .item .logo_link{
  min-width: 40px;
}
.report_items .item .faza{
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: white;
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0px 3px;
  border-radius: 3px;
  border: 1px gray solid;
  line-height: 0.76rem;
  left: 7px;
}
.report_items .item .faza.faza_0{

  border: 1px #a2da7a solid;
  color: #a2da7a;
}
.report_items .item .faza.faza_gray{

  border: 1px #000000 solid;
  color: #000000;
}
.report_items .item .faza.faza_1{

  border: 1px #ff8853 solid;
  color: #ff8853;
}
.report_items .item .info{
  display: flex;
  flex-direction: column;
  /*width: calc(100% - 46px);*/
}
.report_items .item .info .name{
  color: var(--un-text-color);
  /*max-width: 120px;*/
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.report_items .item .info .ttl{
  /* overflow: hidden; */
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .lnk{
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: capitalize;
  color: gray;
  white-space: nowrap;
}
.report_items .item .info .ttl .bn{
  background-color: orange;
  align-items: center;
  justify-content: center;
  padding: 3px;
  display: inline-block;
  border-radius: 3px;
  color: black;
  font-size: 0.9rem;
  margin-left: 4px;
  cursor: pointer;
  margin-top: -2px;
}
.report_items .item .info .ttl .bn{
  background-color: #ffaf1d;
  color: black;
}
.report_items .item .info .ttl .cnt{
  margin-right: 5px;
  background-color: #f3f3f3;
  border-radius: 2px;
  padding: 0px 3px;
}
.report_items .item.partner{
  background-color: #fff8d5;
  padding: 5px;
  border-radius: 3px;
}
.report_items .item .action{
  margin-left: 10px;
}
.report_items .item .action a{
  color: var(--un-text-color);
}
.report_items .ic{
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-right: 10px;
}
.report_items.report_seeds .ic{
  width: 40px;
  height: 40px;
}


.report_items .btn_less{
  position: absolute;
  right: 0px;
  font-size: 1.5rem;
  color: gray;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  background-color:  var(--un-background-color-gray-dark);
  text-align: center;
  bottom: 0px;
  z-index: 2;
  box-shadow: 0px 0px 10px var(--un-background-color), 0px 0px 10px var(--un-background-color), 0px 0px 20px var(--un-background-color);
  border: 5px var(--un-background-color) solid;
}
.report_items.less .btn_less{
  display: inline-block;
}
.report_items.active .btn_less{

}
.report_items .btn_less .down{
  display: inline-block;
  margin: 0px;
}
.report_items.active .btn_less .down{
  display: none;
}
.report_items .btn_less .up{
  display: none;
  margin: 0px;
}
.report_items.active .btn_less .up{
  display: inline-block;
}


@container pb (max-width: 600px) {
  .report_items{
    padding-right: 0px;
  }
}
</style>